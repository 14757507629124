import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Image, Modal, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../../../common/constants';
import { fileUpload, formatDate, hasPermission, openNotification } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import { CREATE_BRAND, UPDATE_BRAND } from '../graphql/Mutations';
import { BRANDS_ADMIN, GET_BRAND_IMAGE_SIGNED_PUT_URL } from '../graphql/Queries';
import BrandModal from './BrandModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const BrandTable = ({ isBrandModalOpen, setIsBrandModalOpen, brandImages, setBrandImages }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [brandList, setBrandList] = useState([]);
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const [isEmptyBrandList, setIsEmptyBrandList] = useState(false);
  const [brand, setBrand] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [brandsPreviewImage, setBrandsPreviewImage] = useState();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isBrandLoading, setIsBrandLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [createBrand] = useMutation(CREATE_BRAND, {
    onError: () => { },
  });
  const [getBrandImageSignedPutUrl] = useLazyQuery(GET_BRAND_IMAGE_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsBtnLoading(false);
    },
  });
  const [updateBrand] = useMutation(UPDATE_BRAND, {
    onError: () => { },
  });

  const [brandsAdmin] = useLazyQuery(BRANDS_ADMIN, {
    onCompleted: (response) => {
      setBrandList([...response?.catalogBrandsAdmin?.data]);
      if (
        response?.catalogBrandsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyBrandList(true);
      } else {
        setIsEmptyBrandList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.catalogBrandsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsBrandLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_BRAND,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBrandLoading(true);
    brandsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: brandSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsBrandLoading(true);
    setSortedInfo(sorter);
    brandsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: brandSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setBrandSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBrandLoading(true);
    brandsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateBrand({
      variables: {
        where: {
          id,
        },
        data: {
          name: brand?.name,
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsBrandLoading(true);
      setIsArchivePrompts(false);
      setBrand();
      brandsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: brandSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleBrand = async (values) => {
    setIsBtnLoading(true);
    const uuid = uuidv4();
    let response;
    let image = '';
    if (values?.image?.length > 0 && !brandImages?.[0]?.url) {
      const { name } = values?.image?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `brands/${uuid}/${newFilename}`;
      const res = await getBrandImageSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getBrandImageSignedPutUrl?.signedUrl,
              brandImages?.[0]?.originFileObj,
            );
            image = res?.data?.getBrandImageSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${brandImages?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsBtnLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    if (!brand) {
      response = await createBrand({
        variables: {
          data: {
            name: values?.name?.trim(),
            imageUuid: uuid,
            image: brandImages?.[0]?.url ? undefined : image,
            order: values?.order,
          },
        },
      });
    } else {
      response = await updateBrand({
        variables: {
          where: {
            id: brand?.id,
          },
          data: {
            name: values?.name?.trim(),
            imageUuid: brand?.imageUuid ? brand?.imageUuid : uuid,
            image: brandImages?.[0]?.url ? undefined : image,
            order: values?.order,
          },
        },
      });
    }
    if (response?.data) {
      setIsBrandModalOpen(false);
      setIsBtnLoading(true);
      setBrand();
      form?.resetFields();
      brandsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: brandSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'BRAND NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'ORDER',
      dataIndex: 'order',
      key: 'order',
      ellipsis: true,
      sorter: false,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.order || '-',
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (

        <span>
          {record?.image ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setBrandsPreviewImage(record?.image);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Image</span>
          )}
        </span>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setBrand(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsBrandModalOpen(true);
                      setBrand(record);
                      setBrandImages(
                        record?.image ? [{ url: record?.image }] : [],
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setBrand(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyBrandList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(brand?.id, brand?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setBrand();
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!brand?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this brand?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={brandsPreviewImage}
        setImage={setBrandsPreviewImage}
      />
      <BrandModal
        form={form}
        onFinish={handleBrand}
        loadings={isBtnLoading}
        brandImages={brandImages}
        setBrandImages={setBrandImages}
        isModalOpen={isBrandModalOpen}
        setVisible={setVisible}
        setIsModalOpen={setIsBrandModalOpen}
        brand={brand}
        setBrand={setBrand}
        setBrandsPreviewImage={setBrandsPreviewImage}
      />

      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={brandList || []}
        loading={isBrandLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default BrandTable;
