import { Divider, Tooltip } from 'antd';
import React from 'react';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info-solid.svg';
import {
  COUPON_TYPE,
  DATE_FORMATS,
  DEVICE_TYPE,
  PAYMENT_PROVIDERS_TYPE,
  PLAN_NAMES,
  PLAN_TYPES,
  SUBSCRIPTION_UPDATED_BY,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import { billableAmount, formatDate } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const InvoiceModal = ({ isModalOpen, setIsModalOpen, inflow }) => {
  const {
    subscription = {},
    transactionNumber,
    user,
    type,
    createdAt,
    price: inflowPrice,
    metaData: inflowMetaData = {},
  } = inflow || {};
  const {
    metaData = {},
    subscriptionPlan = {},
    subscriptionFeatures = {},
    coupon,
    subscriptionId,
  } = subscription || {};
  const { taxesApplied } = inflowMetaData || {};
  const {
    membershipData = {},
    isSecurityDeposit,
    paymentProvider,
    userTrialApplied: isTrialPeriod,
    userDamageWaiverFeesApplied: isDamageWaiver,
    deviceType,
    updatedBy,
  } = metaData || {};
  const { price, type: planType, name } = subscriptionPlan || {};
  const {
    planVersion,
    memberShipFees,
    trialDays,
    membershipFeesApplied,
    actualChargesForSecurityDeposit,
    actualChargesForMemberShipFees,
    securityDepositApplied,
    refundableDeposit,
    trialApplied,
    trialAmount,
    actualChargesForDamageWaiverFees,
    damageWaiverFees,
    damageWaiverFeesApplied,
    Discount,
  } = subscriptionFeatures || {};
  const version = parseInt(planVersion?.split('v')?.[1] || 0, 10);
  const finalDamageWaiverFees = isTrialPeriod
    ? 0
    : actualChargesForDamageWaiverFees;

  const isMembershipFee = membershipData?.isMembershipFee
    ? membershipData?.amount
    : 0;
  const appliedMembershipFees =
    membershipData?.isMembershipFee === true ||
    membershipData?.isMembershipFee === false
      ? isMembershipFee
      : memberShipFees;
  const planVersion2 = version >= process.env.REACT_APP_PLAN_VERSION;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let couponDiscount = 0;
  if (coupon) {
    if (COUPON_TYPE?.FLAT?.value === coupon?.type) {
      couponDiscount = coupon?.value;
    } else if (memberShipFees) {
      const discountPercentage = coupon?.value / 100;
      couponDiscount = appliedMembershipFees * discountPercentage;
    }
  }
  // const isSameRefundableDeposits =
  //   refundableDeposit !== actualChargesForSecurityDeposit;

  let trialAmountValue = 0;
  if (trialApplied && isTrialPeriod) {
    if (securityDepositApplied) {
      if (parseInt(actualChargesForSecurityDeposit, 10) <= 0) {
        trialAmountValue = trialAmount;
      } else {
        trialAmountValue = actualChargesForSecurityDeposit;
      }
    } else {
      trialAmountValue = trialAmount;
    }
  } else {
    trialAmountValue = 0;
  }

  let isMembershipFeesApplied;
  if (membershipFeesApplied) {
    if (planVersion2) {
      isMembershipFeesApplied = parseInt(actualChargesForMemberShipFees, 10);
    } else {
      isMembershipFeesApplied = parseInt(memberShipFees, 10);
    }
  } else {
    isMembershipFeesApplied = 0;
  }
  const isSecurityDepositsApplied = membershipFeesApplied
    ? parseInt(actualChargesForSecurityDeposit, 10)
    : 0;
  const totalAmount =
    isTrialPeriod === true || isTrialPeriod === false || planVersion2
      ? parseInt(
          isTrialPeriod
            ? trialAmountValue + isMembershipFeesApplied
            : price + isSecurityDepositsApplied + isMembershipFeesApplied,
          10,
        ) -
          couponDiscount +
          (isDamageWaiver ? finalDamageWaiverFees : 0) || 0
      : billableAmount(
          price,
          appliedMembershipFees - couponDiscount,
          isSecurityDeposit !== false ? refundableDeposit : 0,
        );

  const gst = inflow?.metaData?.taxesApplied?.percentage
    ? inflowPrice * (inflow?.metaData?.taxesApplied?.percentage / 100)
    : 0;

  let finalPlanCharges;
  if (isTrialPeriod) {
    if (
      trialApplied &&
      (!securityDepositApplied ||
        parseInt(actualChargesForSecurityDeposit, 10) <= 0)
    ) {
      finalPlanCharges = trialAmount;
    } else {
      finalPlanCharges = actualChargesForSecurityDeposit;
    }
  } else {
    finalPlanCharges = price;
  }

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        title={
          type !== TRANSACTION_HISTORY_TYPES.DELIVERY_CHARGED.value
            ? 'Invoice of Subscription Plan'
            : 'Invoice of Delivery Charge'
        }
        footer={null}
      >
        <Divider />
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Invoice Number</div>
          <div className="lib-modal-text-value">{transactionNumber || '-'}</div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">User Name</div>
          <div className="lib-modal-text-value">
            {user?.firstName || user?.lastName
              ? `${user?.firstName || ''} ${user?.lastName || ''}`
              : '-'}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Generated On</div>
          <div className="lib-modal-text-value">
            {formatDate(createdAt, DATE_FORMATS.REGULAR)}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Billing Cycle</div>
          <div className="lib-modal-text-value">
            {PLAN_TYPES?.[planType]?.key || '-'}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Plan Subscribed</div>
          <div className="lib-modal-text-value">
            {PLAN_NAMES?.[name]?.key || '-'}
          </div>
        </div>
        {(isTrialPeriod === true ||
          isTrialPeriod === false ||
          planVersion2) && (
          <div className="d-flex justify-between">
            <div className="lib-modal-text">Damage Waiver Applied</div>
            <div className="lib-modal-text-value">
              {isDamageWaiver ? 'Yes' : 'No'}
            </div>
          </div>
        )}
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Payment Provider</div>
          <div className="lib-modal-text-value d-flex align-center">
            {PAYMENT_PROVIDERS_TYPE?.[paymentProvider]?.label || '-'}
            {paymentProvider && (
              <Tooltip
                title={
                  <>
                    <div>Subscription Id: {subscriptionId || '-'}</div>
                    <div>
                      Device Type:{' '}
                      {DEVICE_TYPE?.[deviceType]?.label || 'No Device Type'}
                    </div>
                  </>
                }
                placement="top"
                zIndex={1000}
                trigger="click"
              >
                <InfoIcon className="ml-8 pointer svg-icon" />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Amount</div>
          <div className="lib-modal-text-value">₹ {inflowPrice || '-'}</div>
        </div>
        {[
          TRANSACTION_HISTORY_TYPES.DELIVERY_CHARGED.value,
          TRANSACTION_HISTORY_TYPES.REFUND_DELIVERY_CHARGED.value,
        ]?.includes(type) && (
          <>
            <div className="d-flex justify-between">
              <div className="lib-modal-text">
                GST{' '}
                {taxesApplied?.percentage && `(${taxesApplied?.percentage}%)`}
              </div>
              <div className="lib-modal-text-value">₹ {gst}</div>
            </div>
            <div className="d-flex justify-between">
              <h4>Total</h4>
              <h4>₹ {inflowPrice + gst}</h4>
            </div>
          </>
        )}
        {![
          TRANSACTION_HISTORY_TYPES.DELIVERY_CHARGED.value,
          TRANSACTION_HISTORY_TYPES.SUBSCRIPTION_RENEWED.value,
        ]?.includes(type) &&
          (updatedBy?.type !== SUBSCRIPTION_UPDATED_BY.ADMIN ||
            isSecurityDeposit !== false ||
            membershipData?.isMembershipFee !== false) && (
            <>
              <hr />
              <h4>Breakdown Details</h4>
              <div className="d-flex justify-between">
                <div className="lib-modal-text">
                  {isTrialPeriod ? `${trialDays} day trial` : 'Plan charges'}{' '}
                </div>
                {finalPlanCharges ? (
                  <div className="lib-modal-text-value">
                    {/* {isTrialPeriod && (
                      <span className="lib-modal-text-line-through mr-8">
                        ₹ {price}
                      </span>
                    )} */}
                    + ₹ {finalPlanCharges}
                  </div>
                ) : (
                  '-'
                )}
              </div>
              {isTrialPeriod ? (
                <>
                  {/* <div className="d-flex justify-between">
                    <div className="lib-modal-text">Refundable deposit</div>
                    <div className="lib-modal-text-value">
                      {isSameRefundableDeposits ? (
                        <span className="lib-modal-text-line-through mr-8">
                          ₹ {refundableDeposit}
                        </span>
                      ) : (
                        ''
                      )}
                      + ₹{' '}
                      {securityDepositApplied
                        ? actualChargesForSecurityDeposit
                        : 0}
                    </div>
                  </div> */}
                  {/* <div className="d-flex justify-between">
                    <div className="lib-modal-text">Membership Fees </div>
                    <div className="lib-modal-text-value">
                      <span className="lib-modal-text-line-through mr-8">
                        ₹ {memberShipFees}
                      </span>
                      + ₹ {membershipFeesApplied ? memberShipFees : 0}
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  {isTrialPeriod === false ? (
                    <>
                      {/* <div className="d-flex justify-between">
                        <div className="lib-modal-text">Refundable deposit</div>
                        <div className="lib-modal-text-value">
                          {isSameRefundableDeposits ? (
                            <span className="lib-modal-text-line-through mr-8">
                              (₹ {refundableDeposit})
                            </span>
                          ) : (
                            ''
                          )}
                          + ₹ {actualChargesForSecurityDeposit}
                        </div>
                      </div> */}
                      {/* <div className="d-flex justify-between">
                        <div className="lib-modal-text">Membership Fees</div>
                        <div className="lib-modal-text-value">
                          + ₹ {memberShipFees}
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <>
                      {isSecurityDeposit !== false && (
                        <div className="d-flex justify-between">
                          <div className="lib-modal-text">
                            Refundable Deposit
                          </div>
                          {refundableDeposit ? (
                            <div className="lib-modal-text-value">
                              + ₹ {refundableDeposit}
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      )}
                      {membershipData?.isMembershipFee !== false && (
                        <div className="d-flex justify-between">
                          <div className="lib-modal-text">Membership Fees</div>
                          {memberShipFees && memberShipFees !== '-' ? (
                            <div className="lib-modal-text-value">
                              + ₹ {appliedMembershipFees}
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {coupon && (
                <div className="d-flex justify-between">
                  <div className="lib-modal-text text-color-green">
                    Coupon Discount{' '}
                    {COUPON_TYPE.FLAT.value !== coupon?.type &&
                      `(${coupon?.value}%)`}{' '}
                    ({coupon?.name}){' '}
                  </div>
                  {coupon !== '-' ? (
                    <div className="lib-modal-text-value text-color-green">
                      - ₹ {couponDiscount}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              )}
              {Discount && (
                <div className="d-flex justify-between">
                  <div className="lib-modal-text">
                    Discount
                    {Discount}
                  </div>
                  <div className="lib-modal-text-value">
                    - ₹ {(totalAmount * Discount) / 100}
                  </div>
                </div>
              )}
              {!(isTrialPeriod === true) &&
                isTrialPeriod === false &&
                isDamageWaiver &&
                damageWaiverFeesApplied && (
                  <div className="d-flex justify-between">
                    <div className="lib-modal-text">Damage Waiver Charges</div>
                    <div className="lib-modal-text-value">
                      <span className="lib-modal-text-line-through mr-8">
                        ₹ {damageWaiverFees}
                      </span>
                      + ₹ {finalDamageWaiverFees}
                    </div>
                  </div>
                )}
              <div className="d-flex justify-between">
                <h4>Total</h4>
                <h4>₹ {totalAmount - ((Discount || 0) * totalAmount) / 100}</h4>
              </div>
            </>
          )}
      </ModalComponent>
    </>
  );
};

export default InvoiceModal;
