import {
  BellOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  ExportOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  PercentageOutlined,
  PhoneOutlined,
  PoweroffOutlined,
  SettingOutlined,
  ShoppingOutlined,
  SmileOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Menu, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ReactComponent as PaymentIcon } from '../../../assets/svg/rupee-symbol.svg';
import {
  MODULES,
  PERMISSIONS_KEY,
  ROUTES,
  SIDEBAR_ADMIN_DROPDOWNS,
  SIDEBAR_LIBRARY_DROPDOWNS,
  USER_ROLES,
} from '../../../common/constants';
import {
  hasMatchingTabKey,
  hasNonEmptyPermission,
} from '../../../common/utils';
import LogoTextComponent from '../../../components/LogoTextComponent';
import MobileLogoComponent from '../../../components/MobileLogoComponent';
import {
  ADMIN_NOTIFICATIONS,
  LIBRARY_NOTIFICATIONS,
} from '../../graphql/Queries';

function getItem(label, key, icon, children, onTitleClick, type) {
  const item = {
    key,
    icon,
    children,
    label,
    title: label,
    type,
  };

  if (onTitleClick) {
    item.onTitleClick = (data) => onTitleClick(children, data);
  }

  return item;
}

const lineBarMenuItems = [
  getItem(MODULES?.PROFILE, ROUTES?.PROFILE, <UserOutlined />),
  getItem(MODULES?.LOGOUT, ROUTES?.LOGOUT, <PoweroffOutlined />),
];

export const MENU_ITEMS = [
  {
    key: MODULES?.DASHBOARD,
    route: ROUTES?.MAIN,
    icon: <DashboardOutlined />,
    permissionKey: PERMISSIONS_KEY?.OVERVIEW,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.PAYMENTS,
    route: ROUTES?.PAYMENTS,
    icon: (
      <span className="indian-rupee-icon">
        <PaymentIcon className="indian-rupee" />
      </span>
    ),
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.END_USER,
        route: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.INFLOW,
            tabKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_INFLOW,
          },
          {
            route: ROUTES?.OUTFLOW,
            tabKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
          },
        ],
      },
      {
        key: MODULES?.LIBRARY,
        route: `${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.INVOICES,
            tabKey: PERMISSIONS_KEY?.PAYMENTS_LIBRARY,
          },
          {
            route: ROUTES?.REFERRAL,
            tabKey: PERMISSIONS_KEY?.PAYMENTS_LIBRARY_REFERRAL,
          },
        ],
      },
    ],
  },
  {
    key: MODULES?.REQUESTS,
    route: ROUTES?.REQUESTS,
    icon: <MessageOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.END_USER,
        route: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.SUGGESTED_TOY,
            tabKey: PERMISSIONS_KEY?.REQUESTS_END_USER_SUGGESTED_TOY,
          },
          {
            route: ROUTES?.CHANGE_ADDRESS,
            tabKey: PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS,
          },
          {
            route: ROUTES?.REPORT_DAMAGE,
            tabKey: PERMISSIONS_KEY?.REQUESTS_END_USER_REPORT_DAMAGE,
          },
        ],
      },
      {
        key: MODULES?.LIBRARY,
        route: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.DAMAGES,
            tabKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_DAMAGES,
          },
          {
            route: ROUTES?.CLOSURE_REQUESTS,
            tabKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_CLOSURE_REQUESTS,
          },
          {
            route: ROUTES?.BAGS,
            tabKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_BAGS,
          },
          {
            route: ROUTES?.NEW_TOYS,
            tabKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_NEW_TOYS,
          },
        ],
      },
    ],
  },
  {
    key: MODULES?.ORDERS,
    route: ROUTES?.ORDERS,
    icon: <ShoppingOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.NEW,
        route: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.CONFIRM_ORDER,
            tabKey: PERMISSIONS_KEY?.ORDERS_NEW_PLACED,
          },
          {
            route: ROUTES?.ASSIGN_DELIVERY_PARTNER,
            tabKey: PERMISSIONS_KEY?.ORDERS_NEW_CONFIRMED,
          },
          // {
          //   route: ROUTES?.DELIVERY,
          //   tabKey: PERMISSIONS_KEY?.ORDERS_NEW_DELIVERY,
          // },
          {
            route: ROUTES?.RETURN,
            tabKey: PERMISSIONS_KEY?.ORDERS_NEW_RETURN,
          },
        ],
      },
      {
        key: MODULES?.ALL,
        route: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        permissionKey: '',
        tabKeys: [
          {
            route: ROUTES?.DELIVERY,
            tabKey: PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
          },
          {
            route: ROUTES?.RETURN,
            tabKey: PERMISSIONS_KEY?.ORDERS_ALL_RETURN,
          },
        ],
      },
    ],
  },
  {
    key: MODULES?.REPORTS,
    route: ROUTES?.REPORTS,
    icon: <FileTextOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.BULK_UPDATE,
        route: `${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}`,
        permissionKey: PERMISSIONS_KEY?.REPORTS_BULK_UPDATE,
        tabKeys: [],
      },
      {
        key: MODULES?.HOLDING_PERIOD,
        route: `${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`,
        permissionKey: PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD,
        tabKeys: [],
      },
      {
        key: MODULES?.UNFULFILLED_ORDERS,
        route: `${ROUTES?.REPORTS}${ROUTES?.UNFULFILLED_ORDERS}`,
        permissionKey: PERMISSIONS_KEY?.REPORTS_UNFULFILLED_ORDERS,
        tabKeys: [],
      },
      {
        key: MODULES?.CUSTOM_REPORTS,
        route: `${ROUTES?.REPORTS}${ROUTES?.CUSTOM_REPORTS}`,
        permissionKey: PERMISSIONS_KEY?.REPORTS_CUSTOM,
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.APP_CONTENT,
    route: ROUTES?.APP_CONTENT,
    icon: <FileTextOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.GENRES,
        route: `${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}`,
        permissionKey: PERMISSIONS_KEY?.APP_CONTENT_GENRES,
        tabKeys: [],
      },
      {
        key: MODULES?.COLLECTION,
        route: `${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}`,
        permissionKey: PERMISSIONS_KEY?.APP_CONTENT_COLLECTION,
        tabKeys: [],
      },
      {
        key: MODULES?.STORY,
        route: `${ROUTES?.APP_CONTENT}${ROUTES?.STORY}`,
        permissionKey: PERMISSIONS_KEY?.APP_CONTENT_STORY,
        tabKeys: [],
      },
      {
        key: MODULES?.BANNER,
        route: `${ROUTES?.APP_CONTENT}${ROUTES?.BANNER}`,
        permissionKey: PERMISSIONS_KEY?.APP_CONTENT_BANNER,
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.MASTER_LISTS,
    route: ROUTES?.MASTER_LISTS,
    icon: <ContainerOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.LIBRARY,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY,
        tabKeys: [
          {
            route: ROUTES?.BASIC_DETAILS,
            tabKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS,
          },
          {
            route: ROUTES?.INVENTORY_DETAILS,
            tabKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
          },
        ],
      },
      {
        key: MODULES?.TOY,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_TOY,
        tabKeys: [],
      },
      {
        key: MODULES?.CATEGORY,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.CATEGORY}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_CATEGORY,
        tabKeys: [],
      },
      {
        key: MODULES?.AGE_GROUP,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.AGE_GROUP}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP,
        tabKeys: [],
      },
      {
        key: MODULES?.BRAND,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.BRAND}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_BRAND,
        tabKeys: [],
      },
      {
        key: MODULES?.MATERIAL,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.MATERIAL}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_MATERIAL,
        tabKeys: [],
      },
      {
        key: MODULES?.PACKAGING_MATERIAL,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.PACKAGING_MATERIAL}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_BAG,
        tabKeys: [],
      },
      {
        key: MODULES?.TAGS,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.TAGS}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP,
        tabKeys: [],
      },
      {
        key: MODULES?.FACILITATES,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.FACILITATES}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP,
        tabKeys: [],
      },
      {
        key: MODULES?.CONTENT_MASTER,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.CONTENT_MASTER}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_CONTENT,
        tabKeys: [],
      },
      {
        key: MODULES?.PINCODE,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_PINCODE,
        tabKeys: [],
      },
      {
        key: MODULES?.PINCODE_MAPPING,
        route: `${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE_MAPPING}`,
        permissionKey: PERMISSIONS_KEY?.MASTER_LIST_PINCODE,
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.SYSTEM_USERS,
    route: ROUTES?.SYSTEM_USERS,
    icon: <TeamOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.ADMINS,
        route: `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`,
        permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
        tabKeys: [],
      },
      {
        key: MODULES?.END_USERS,
        route: `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`,
        permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
        tabKeys: [],
      },
      {
        key: MODULES?.DELIVERY_PARTNERS,
        route: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
        permissionKey: PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS,
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.SUBSCRIPTION_PLANS,
    route: ROUTES?.SUBSCRIPTION_PLANS,
    icon: <CreditCardOutlined />,
    permissionKey: PERMISSIONS_KEY?.SUBSCRIPTION_PLANS,
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.GARDEN,
        route: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.ESTATE,
        route: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.FOREST,
        route: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.TRIAL,
        route: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.NEW_PLANS,
        route: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}`,
        permissionKey: '',
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.OFFERS,
    route: ROUTES?.OFFERS,
    icon: <PercentageOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.GIFT_CARD,
        route: `${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`,
        permissionKey: PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
        tabKeys: [],
      },
      {
        key: MODULES?.COUPON,
        route: `${ROUTES?.OFFERS}${ROUTES?.COUPON}`,
        permissionKey: PERMISSIONS_KEY?.OFFERS_COUPONS,
        tabKeys: [],
      },
      {
        key: MODULES?.REFERRAL,
        route: `${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`,
        permissionKey: PERMISSIONS_KEY?.OFFERS_REFERRAL_USERS,
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.HOME_VIDEO,
    route: ROUTES?.HOME_VIDEO,
    icon: <VideoCameraOutlined />,
    permissionKey: PERMISSIONS_KEY?.VIDEOS,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.CONFIGS,
    route: ROUTES?.CONFIGS,
    icon: <SettingOutlined />,
    permissionKey: PERMISSIONS_KEY?.CONFIGS,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.UI_LABELS,
    route: ROUTES?.UI_LABELS,
    icon: <SolutionOutlined />,
    permissionKey: PERMISSIONS_KEY?.UI_LABELS,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.EXPORTS,
    route: ROUTES?.EXPORTS,
    icon: <ExportOutlined />,
    permissionKey: PERMISSIONS_KEY?.EXPORTS,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.NOTIFICATIONS,
    route: ROUTES?.NOTIFICATIONS,
    icon: <BellOutlined />,
    permissionKey: PERMISSIONS_KEY?.NOTIFICATIONS,
    tabKeys: [],
    subMenu: [],
  },
];

const rootSubmenuKeys = [
  ROUTES?.PAYMENTS,
  ROUTES?.REQUESTS,
  ROUTES?.ORDERS,
  ROUTES?.REPORTS,
  ROUTES?.APP_CONTENT,
  ROUTES?.MASTER_LISTS,
  ROUTES?.SYSTEM_USERS,
  ROUTES?.SUBSCRIPTION_PLANS,
];

function Sidebar({ collapsed, setCollapsed, isDesktop, isActive, setActive }) {
  const history = useHistory();
  const location = useLocation();
  const {
    getCurrentUser,
    dispatch,
    state: { permissions, notificationCount },
  } = useContext(AppContext);
  const [openKeys, setOpenKeys] = useState([]);
  const { firstName = '', lastName = '', roles } = getCurrentUser() || {};
  const [isLogoutPrompts, setIsLogoutPrompts] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const fullName =
    firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '-';

  const [adminNotifications] = useLazyQuery(ADMIN_NOTIFICATIONS, {
    onCompleted: (response) => {
      dispatch({
        type: 'SET_NOTIFICATION_COUNT',
        data: response?.adminNotifications?.count,
      });
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraryNotifications] = useLazyQuery(LIBRARY_NOTIFICATIONS, {
    onCompleted: (response) => {
      dispatch({
        type: 'SET_NOTIFICATION_COUNT',
        data: response?.libraryNotifications?.count,
      });
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (roles?.includes(USER_ROLES?.LIBRARIAN?.key)) {
      libraryNotifications();
    } else {
      adminNotifications();
    }
  }, []);

  const handleSubMenuTitleClick = (children, data) => {
    const isInclude = (roles?.includes(USER_ROLES?.LIBRARIAN?.key)
      ? SIDEBAR_LIBRARY_DROPDOWNS
      : SIDEBAR_ADMIN_DROPDOWNS
    )?.includes(location?.pathname?.split('/')?.[1]);
    if (isInclude && !location?.pathname?.includes(data?.key)) {
      setSelectedKeys(children?.[0]?.key);
      history?.push(children?.[0]?.key);
    }
  };

  const librarianMenuItems = [
    getItem(MODULES?.DASHBOARD, ROUTES?.MAIN, <DashboardOutlined />),
    getItem(
      MODULES?.ORDERS,
      ROUTES?.ORDERS,
      <ShoppingOutlined />,
      [
        getItem(MODULES?.NEW, `${ROUTES?.ORDERS}${ROUTES?.NEW}`),
        getItem(MODULES?.ALL, `${ROUTES?.ORDERS}${ROUTES?.ALL}`),
      ],
      handleSubMenuTitleClick,
    ),
    getItem(MODULES?.INVENTORY, ROUTES?.INVENTORY, <ContainerOutlined />),
    getItem(
      MODULES?.PAYMENTS,
      ROUTES?.PAYMENTS,
      <span className="indian-rupee-icon">
        <PaymentIcon className="indian-rupee" />
      </span>,
    ),
    getItem(MODULES?.RAISE_REQUEST, ROUTES?.RAISE_REQUEST, <MessageOutlined />),
    getItem(
      MODULES?.OFFERS,
      ROUTES?.OFFERS,
      <PercentageOutlined />,
      [
        getItem(MODULES?.COUPON, `${ROUTES?.OFFERS}${ROUTES?.COUPON}`),
        getItem(MODULES?.REFERRAL, `${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`),
      ],
      handleSubMenuTitleClick,
    ),
    getItem(MODULES?.EXPORTS, ROUTES?.EXPORTS, <ExportOutlined />),
    getItem(
      MODULES?.NOTIFICATIONS,
      ROUTES?.NOTIFICATIONS,
      <Badge dot={notificationCount > 0} size="small">
        <BellOutlined />
      </Badge>,
    ),
    getItem(MODULES?.SUPPORT, ROUTES?.SUPPORT, <PhoneOutlined />),
  ];

  useEffect(() => {
    const isInclude = (roles?.includes(USER_ROLES?.LIBRARIAN?.key)
      ? SIDEBAR_LIBRARY_DROPDOWNS
      : SIDEBAR_ADMIN_DROPDOWNS
    )?.includes(location?.pathname?.split('/')?.[1]);
    setOpenKeys([`/${location?.pathname?.split('/')?.[1]}`]);
    if (location?.pathname === '/') {
      setSelectedKeys([ROUTES?.MAIN]);
    }
    if (isInclude) {
      setSelectedKeys([
        `/${location?.pathname?.split('/')?.[1]}/${
          location?.pathname?.split('/')?.[2]
        }`,
      ]);
    } else {
      setSelectedKeys([`/${location?.pathname?.split('/')?.[1]}`]);
    }
  }, [location?.pathname]);

  const filteredMenuItems = MENU_ITEMS?.map((menuItem) => {
    const { permissionKey, subMenu } = menuItem;
    const hasPermission = hasNonEmptyPermission(permissionKey, permissions);
    const hasMatchingSubmenu = subMenu?.some((subMenuItem) => {
      const { permissionKey: subPermissionKey } = subMenuItem;
      return hasNonEmptyPermission(subPermissionKey, permissions);
    });
    const hasMatchingTab = subMenu?.some((subMenuItem) => {
      const { tabKeys } = subMenuItem;
      return hasMatchingTabKey(tabKeys, permissions);
    });
    const filteredSubMenu = subMenu?.map((subMenuItem) => {
      const {
        permissionKey: subPermissionKey,
        tabKeys: subTabKeys,
      } = subMenuItem;
      const hasPermissionSubmenu = hasNonEmptyPermission(
        subPermissionKey,
        permissions,
      );
      const hasMatchingTabSubmenu = hasMatchingTabKey(subTabKeys, permissions);
      return hasPermissionSubmenu || hasMatchingTabSubmenu || hasPermission
        ? getItem(subMenuItem?.key, subMenuItem?.route)
        : undefined;
    });
    if (hasPermission || hasMatchingTab || hasMatchingSubmenu) {
      if (menuItem?.key !== MODULES?.NOTIFICATIONS) {
        return getItem(
          menuItem?.key,
          menuItem?.route,
          menuItem?.icon,
          filteredSubMenu?.length > 0 ? filteredSubMenu : undefined,
          filteredSubMenu?.length && handleSubMenuTitleClick,
        );
      }
      return getItem(
        menuItem?.key,
        menuItem?.route,
        <Badge dot={notificationCount > 0} size="small">
          {menuItem?.icon}
        </Badge>,
        filteredSubMenu?.length > 0 ? filteredSubMenu : undefined,
        filteredSubMenu?.length && handleSubMenuTitleClick,
      );
    }
    return undefined;
  })?.filter((item) => item);

  const onMenuSelect = (e) => {
    if (e?.key === ROUTES?.LOGOUT) {
      setIsLogoutPrompts(true);
    } else {
      history?.push(e?.key);
      setActive(!isActive);
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys?.find((key) => openKeys?.indexOf(key) === -1);
    if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    setTimeout(() => {
      setActive(!isActive);
    }, [600]);
    history?.push(keys?.[1]);
  };

  return (
    <div className="side-bar">
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isLogoutPrompts}
        onOk={() => history?.replace(ROUTES?.LOGOUT)}
        onCancel={() => setIsLogoutPrompts(false)}
      >
        Are you sure you want to
        <strong> logout </strong>?
      </Modal>
      <div>
        {collapsed ? (
          <div className="d-flex align-center flex-vertical">
            <MenuUnfoldOutlined
              className="trigger menu-collapsed-unfold"
              onClick={() => setCollapsed(!collapsed)}
            />
            <Link id="logo" to={ROUTES?.MAIN}>
              <MobileLogoComponent className="mr-0" height="38px" />
            </Link>
          </div>
        ) : (
          <div className="d-flex justify-center">
            <Link id="logo" to={ROUTES?.MAIN}>
              <MobileLogoComponent className="mr-8" height="38px" />
              <LogoTextComponent height="16px" />
            </Link>
            {collapsed && isDesktop ? (
              <MenuUnfoldOutlined
                className="trigger mr-32"
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <>
                {isDesktop && (
                  <MenuFoldOutlined
                    className="trigger ml-32"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="sidebar-content">
        <Menu
          theme="light"
          mode="inline"
          openKeys={collapsed ? undefined : openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          defaultOpenKeys={[`/${location?.pathname?.split('/')?.[1]}`]}
          onClick={onMenuSelect}
          items={
            roles?.includes(USER_ROLES?.LIBRARIAN?.key)
              ? librarianMenuItems
              : filteredMenuItems
          }
        />
      </div>
      <div className="sidebar-bottom-content">
        {!collapsed ? (
          <div className=" d-flex flex-vertical align-center">
            <div
              className={`d-flex ${
                location?.pathname?.includes(ROUTES?.PROFILE) &&
                'sidebar-profile'
              }`}
            >
              <SmileOutlined />
              <div
                className="pointer ml-8 user-name"
                title={fullName}
                onClick={() => {
                  history?.replace(ROUTES?.PROFILE);
                  setActive(!isActive);
                }}
              >
                {fullName}
              </div>
            </div>
            <div
              className="mt-16 logout-btn pointer"
              onClick={() => {
                setIsLogoutPrompts(true);
                setActive(!isActive);
              }}
            >
              LOGOUT
            </div>
          </div>
        ) : (
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            defaultOpenKeys={[`/${location?.pathname?.split('/')?.[1]}`]}
            onClick={onMenuSelect}
            items={lineBarMenuItems}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
