import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Radio, Row, Typography, Upload } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { VIDEO_STATUS, VIDEO_TYPE } from '../../../../common/constants';
import {
  formValidatorRules,
  validateVideo,
} from '../../../../common/utils';
import CommonPreview from '../../../../components/CommonPreview';
import ModalComponent from '../../../../components/ModalComponent';
import ToySelect from './toySelect';

const { Item } = Form;
const { Group } = Radio;
const { required, url } = formValidatorRules;
const { Text } = Typography;

const StoryAddTable = ({
  title,
  videoData,
  setVideoData,
  videoType,
  setVideoType,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  thumbVideoError,
  setThumbVideoError,
  videoError,
  setVideoError,
  form,
  toyVideo,
  setToyVideo,
  toyPreviewVideo,
  setToyPreviewVideo,
  thumbVideo,
  setthumbVideo,
  thumbPreviewVideo,
  setthumbPreviewVideo,
  setToySelectValue,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [toySelectno, setToySelectno] = useState(null);
  const [toySelectname, setToySelectname] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleVideoChange = async (info) => {
    const res = await validateVideo(info, form, setToyVideo, 'video');
    if (res) {
      setToyPreviewVideo(
        URL.createObjectURL(info?.fileList?.[0]?.originFileObj),
      );
    }
  };


  const handlethumbVideoChange = async (info) => {
    const res = await validateVideo(info, form, setthumbVideo, 'thumbvideo');
    if (res) {
      setthumbPreviewVideo(
        URL.createObjectURL(info?.fileList?.[0]?.originFileObj),
      );
    }
  };


  useEffect(() => {

    if (videoData) {
      const initialValues = {
        ...videoData,
        ...(videoData?.type === VIDEO_TYPE?.INTERNAL?.value
          ? {
            thumbvideo: [
              {
                key: videoData?.thumbnailKey,
                url: videoData?.thumbnailUrl,
              },
            ],
            video: [
              {
                key: videoData?.key,
                url: videoData?.url,
              },
            ],
          }
          : {
            videoUrl: videoData?.url,
          }),
      };
      form?.setFieldsValue(initialValues);
      setVideoType(videoData?.type);
      if (videoData?.toy) {
        setToySelectno(videoData.toy.toyNumber);
        setToySelectname(videoData.toy.name);
      }
      else {
        setToySelectno(null);
        setToySelectname(null);
      }
      if (videoData?.type === VIDEO_TYPE?.INTERNAL?.value) {
        setToyPreviewVideo(videoData?.url);
        setthumbPreviewVideo(videoData?.thumbnailUrl);
      }
    }
  }, [videoData]);

  const handleOk = () => {
    form?.submit();
    setToySelectname(null);
    setToySelectno(null);
  };
  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
      />
      <ModalComponent
        title={title}
        okText="Save"
        cancelText="Cancel"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          setIsModalOpen(false);
          setToyVideo([]);
          setthumbVideo([]);
          setToyPreviewVideo(null);
          setToySelectname(null);
          setToySelectno(null);
          setthumbPreviewVideo(null);
          setVideoData(null);
          setThumbVideoError('');
          setVideoError('');
          setVideoType(VIDEO_TYPE?.INTERNAL?.value);
          form?.resetFields();
        }}
        confirmLoading={isBtnLoading}
        forceRender
      >
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Row>
            <Col xs={24}>
              <Item
                label={
                  toySelectno && toySelectname
                    ? (
                      <Text>
                        Toys ( <Text strong>Selected Toy</Text> :  <Text>{toySelectname}</Text> )
                      </Text>
                    )
                    : "Toys"
                }
                name="toyId"
              >
                <ToySelect
                  onChange={(values) => setToySelectValue(values)}
                />
              </Item>
            </Col>
            {/* <Col xs={24}>
              <Item
                name="description"
                label="Description"
                rules={[whitespaceNotAllowed]}
              >
                <TextArea
                  className='text-area-padding'
                  rows={3}
                  allowClear
                  placeholder="Enter Description"
                  showCount
                  maxLength={700}
                />
              </Item>
            </Col> */}
            {/* <Col xs={12}>
              <Item
                label="Type"
                name="type"
                initialValue={VIDEO_TYPE?.INTERNAL?.value}
              >
                <Group
                  options={map(VIDEO_TYPE, (record) => record)}
                  onChange={onTypeChange}
                />
              </Item>
            </Col> */}
            <Col xs={12}>
              <Item
                label="Status"
                name="status"
                initialValue={VIDEO_STATUS?.DRAFT?.value}
              >
                <Group options={map(VIDEO_STATUS, (record) => record)} />
              </Item>
            </Col>
            {videoType === VIDEO_TYPE?.INTERNAL?.value ? (
              <>
                <Col xs={24}>
                  <Item
                    label="Thumbnail Video"
                    name="thumbvideo"
                    rules={[
                      { required: true, message: 'Please Upload Thumbnail Video!' },
                    ]}
                  >
                    {!thumbPreviewVideo && !thumbVideo?.url ? (
                      <>
                        <Upload
                          listType="picture-card"
                          maxCount={1}
                          fileList={thumbVideo}
                          beforeUpload={() => false}
                          onChange={handlethumbVideoChange}
                          showUploadList={{
                            showDownloadIcon: false,
                            showPreviewIcon: false,
                            showRemoveIcon: false,
                          }}
                        >
                          {thumbVideo?.length !== 1 && <PlusOutlined />}
                        </Upload>

                      </>
                    ) : (
                      <>
                        <div className="toy-video-container mb-10">
                          <video
                            width="100%"
                            height="100%"
                            controls
                            src={thumbPreviewVideo || thumbVideo?.url}
                            className="toy-video-upload"
                          >
                            <track kind="captions" />
                          </video>
                          <DeleteFilled
                            className="toy-story-delete-icon-video"
                            onClick={() => {
                              setthumbVideo([]);
                              setthumbPreviewVideo(null);
                              setThumbVideoError('');
                              form.
                                setFieldsValue
                                ({
                                  thumbvideo
                                    : undefined,
                                });
                            }}
                          />
                        </div>
                        <div style={{ color: 'red', marginTop: '20px', fontSize: '15px' }}>{thumbVideoError || ''}</div>

                      </>
                    )}
                  </Item>
                </Col>
                <Col xs={24}>
                  <Item
                    label="Video"
                    name="video"
                    rules={[
                      { required: true, message: 'Please Upload Video!' },
                    ]}
                  >
                    {!toyPreviewVideo && !toyVideo?.url ? (
                      <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={toyVideo}
                        beforeUpload={() => false}
                        onChange={handleVideoChange}
                        showUploadList={{
                          showDownloadIcon: false,
                          showPreviewIcon: false,
                          showRemoveIcon: false,
                        }}
                      >
                        {toyVideo?.length !== 1 && <PlusOutlined />}
                      </Upload>
                    ) : (
                      <>
                        <div className="toy-video-container mb-10">
                          <video
                            width="100%"
                            height="100%"
                            controls
                            src={toyPreviewVideo || toyVideo?.url}
                            className="toy-video-upload"
                          >
                            <track kind="captions" />
                          </video>
                          <DeleteFilled
                            className="toy-story-delete-icon-video"
                            onClick={() => {
                              setToyVideo([]);
                              setToyPreviewVideo(null);
                              setVideoError('');
                              form.
                                setFieldsValue
                                ({
                                  video
                                    : undefined,
                                })
                            }}
                          />
                        </div>
                        <div style={{ color: 'red', marginTop: '20px', fontSize: '15px' }}>{videoError || ''}</div>
                      </>
                    )}
                  </Item>
                </Col>
              </>
            ) : (
              <Col xs={24}>
                <Item
                  label="Video URL"
                  name="videoUrl"
                  rules={[
                    {
                      ...required,
                      message: 'Please Enter Video URL!',
                    },
                    url,
                  ]}
                >
                  <Input allowClear placeholder="Enter Video URL" />
                </Item>
              </Col>
            )}
            <Col xs={24} className='notes'>
              <div>Video Notes </div>
              <ul >
                <li>
                  Thumbnail Video should be maximum of 5 seconds
                </li>
                <li>
                  Video should be maximum of 30 seconds
                </li>
                <li>
                  Video size should not be more than 20 MB
                </li>
                <li>
                  Only MP4 video allowed.
                </li>
              </ul>
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default StoryAddTable;
