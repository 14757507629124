import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BRANDS_ADMIN = gql`
  query CatalogBrandsAdmin($filter: BrandFilter, $sort: BrandSort) {
    catalogBrandsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        order
        image
        imageUuid
        isArchived
        createdAt
      }
    }
  }
`;

export const GET_BRAND_IMAGE_SIGNED_PUT_URL = gql`
  query GetBrandImageSignedPutUrl($data: GetBrandsImageSignedPutUrlInput!) {
    getBrandImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;