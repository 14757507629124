import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import {
  CANCELLATION_TYPE,
  DATE_FORMATS,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  FULFILLMENT_STATUS,
  LIMIT,
  NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  NEW_ORDER_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
  ORDER_TYPE,
  ROUTES,
  SORT,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import { PINCODES } from '../../../../../components/graphql/Queries';
import AddCommentModal from '../../../../../libraryModules/orders/modules/all/components/AddCommentModal';
import { ORDERS_SELECT } from '../../../../../libraryModules/orders/modules/all/graphql/Queries';
import { USERS } from '../../../../../libraryModules/raiseRequest/graphql/Queries';
import UserSelect from '../../../../payments/modules/endUser/components/UserSelect';
import ContentMasterModal from '../../all/components/ContentMasterModal';
import NoteModal from '../../all/components/NoteModal';
import { CANCEL_ORDER_ADMIN } from '../../all/graphql/Mutations';
import { ORDER_ITEMS_ADMIN } from '../../all/graphql/Queries';
import {
  ASSIGN_DELIVERY_PARTNER_V2,
  UPDATE_ORDER_STATUS_ADMIN,
  UPDATE_ORDER_STATUS_ADMIN_V2,
} from '../graphql/Mutations';
import { NEW_ORDERS_ADMIN_V2 } from '../graphql/Queries';
import AssignDeliveryPartnerModal from './AssignDeliveryPartnerModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialNewDeliveryFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialNewDeliverySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const NewOrderTable = ({
  exportFilter,
  setExportFilter,
  isAssignDelPartnerTab,
}) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [assignDelPartnerForm] = Form.useForm();
  const {
    state: {
      commonPermissions: {
        isUserPermission,
        isToyPermission,
        isDeliveryPartnerPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const queryParams = new URLSearchParams(location?.search);
  const orderId = queryParams?.get('orderId') || '';
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
  const [isAddCommentLoading, setIsAddCommentLoading] = useState(false);
  const [isOrderAction, setIsOrderAction] = useState(false);
  const [isFulfillLoading, setIsFulfillLoading] = useState(false);
  const [isFulfillPrompts, setIsFulfillPrompts] = useState(false);
  const [isReadyLoading, setIsReadyLoading] = useState(false);
  const [isReadyPrompts, setIsReadyPrompts] = useState(false);
  const [isConfirmPrompts, setIsConfirmPrompts] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDeliveryPartnerLoading, setIsDeliveryPartnerLoading] = useState(
    false,
  );
  const [isDeliveryPartnerPrompts, setIsDeliveryPartnerPrompts] = useState(
    false,
  );
  const [deliveryPartnerData, setDeliveryPartnerData] = useState({});
  const [isDeliveryPartnerModalOpen, setDeliveryPartnerModalOpen] = useState(
    false,
  );

  const statusFilter = isAssignDelPartnerTab
    ? [ORDER_STATUS?.READY_TO_SHIP?.value, ORDER_STATUS?.CONFIRMED?.value]
    : [ORDER_STATUS?.PLACED?.value, ORDER_STATUS?.CONFIRMED?.value];

  const [newOrdersAdminV2] = useLazyQuery(NEW_ORDERS_ADMIN_V2, {
    onCompleted: (response) => {
      setOrderList([...response?.newOrdersAdminV2?.data]);
      if (
        response?.newOrdersAdminV2?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.newOrdersAdminV2?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [orderItemsAdmin] = useLazyQuery(ORDER_ITEMS_ADMIN, {
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${
            item?.firstName || item?.lastName
              ? `${item?.firstName || ''} ${item?.lastName || ''}`
              : '-'
          }`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.pincodes?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.pincodes?.data, (item) =>
        optionsCopy?.push({
          label: item?.pincode,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [orderSelect, { loading: isOrderSelectLoading }] = useLazyQuery(
    ORDERS_SELECT,
    {
      onCompleted: (response) => {
        if (response?.newOrdersAdminV2?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.newOrdersAdminV2?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.newOrdersAdminV2?.data, (item) =>
          optionsCopy?.push({
            label: item?.orderNumber,
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [cancelOrderAdmin] = useMutation(CANCEL_ORDER_ADMIN, {
    onError: () => {},
  });

  const [updateOrderStatusAdmin] = useMutation(UPDATE_ORDER_STATUS_ADMIN, {
    onError: () => {},
  });

  const [updateOrderStatusAdminV2] = useMutation(UPDATE_ORDER_STATUS_ADMIN_V2, {
    onError: () => {},
  });

  const [assignDeliveryPartnerV2] = useMutation(ASSIGN_DELIVERY_PARTNER_V2, {
    onError: () => {},
  });

  useEffect(() => {
    setOrderSearchTerm(orderId || orderSearchTerm);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    newOrdersAdminV2({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderId || orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.NEW,
          orderIds: finalWhereFilters?.orderNumber,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : statusFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdminV2({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          skip,
          limit: pagination?.pageSize,
          orderType: ORDER_TYPE?.NEW,
          orderIds: finalWhereFilters?.orderNumber,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : statusFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdminV2({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.NEW,
          orderIds: finalWhereFilters?.orderNumber,
          search: trimValue,
          deliveryType: finalWhereFilters?.deliveryType,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : statusFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          pincodeIds: finalWhereFilters?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAddCommentInOrder = async (values) => {
    setIsAddCommentLoading(true);
    const response = await cancelOrderAdmin({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          additionalComment: values?.comment?.trim(),
          reason: values?.reason,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsAddCommentModalOpen(false);
      setNestedData({});
      setExpandedRowKeys([]);
      setOrderList([]);
      form?.resetFields();
      newOrdersAdminV2({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.NEW,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : statusFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsAddCommentLoading(false);
  };

  const handleAddCommentInOrderItem = async (values) => {
    setIsAddCommentLoading(true);
    const response = await updateOrderStatusAdmin({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          orderItemId: orderItem?.id,
          additionalComment: values?.comment?.trim(),
          reason: values?.reason,
          status: FULFILLMENT_STATUS?.UNFULFILLED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsAddCommentModalOpen(false);
      setNestedData({});
      setExpandedRowKeys([]);
      setOrderList([]);
      form?.resetFields();
      newOrdersAdminV2({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.NEW,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : statusFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsAddCommentLoading(false);
  };

  const handleFulfillOrderItem = async () => {
    setIsFulfillLoading(true);
    const response = await updateOrderStatusAdmin({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          orderItemId: orderItem?.id,
          status: FULFILLMENT_STATUS?.FULFILLED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsFulfillPrompts(false);
      setNestedData({});
      setOrderList([]);
      setExpandedRowKeys([]);
      newOrdersAdminV2({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.NEW,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : statusFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsFulfillLoading(false);
  };

  const handleReadyOrder = async (values) => {
    setIsReadyLoading(true);
    const response = await updateOrderStatusAdminV2({
      variables: {
        where: {
          id: values,
        },
        data: {
          status: ORDER_STATUS?.CONFIRMED?.value,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsReadyPrompts(false);
      setNestedData({});
      setOrderList([]);
      setSelectedRowKeys([]);
      setExpandedRowKeys([]);
      setIsConfirmPrompts(false);
      newOrdersAdminV2({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.NEW,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : statusFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsReadyLoading(false);
  };

  const handleAssignDeliveryPartner = async (rowKeys, deliveryPartner) => {
    setIsDeliveryPartnerLoading(true);
    const response = await assignDeliveryPartnerV2({
      variables: {
        where: {
          id: rowKeys,
        },
        data: {
          deliveryPartnerId: deliveryPartner,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setDeliveryPartnerModalOpen(false);
      setNestedData({});
      setOrderList([]);
      setExpandedRowKeys([]);
      setSelectedRowKeys([]);
      setIsDeliveryPartnerPrompts(false);
      setDeliveryPartnerData({});
      assignDelPartnerForm.resetFields();
      newOrdersAdminV2({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.NEW,
            orderIds: finalWhereFilters?.orderNumber,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : statusFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            pincodeIds: finalWhereFilters?.pincodes,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsDeliveryPartnerLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdminV2({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          orderType: ORDER_TYPE?.NEW,
          orderIds: whereFilter?.orderNumber,
          deliveryType: whereFilter?.deliveryType,
          status:
            whereFilter?.status?.length > 0
              ? whereFilter?.status
              : statusFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFilter?.deliveredOn?.from?.$d,
            to: whereFilter?.deliveredOn?.to?.$d,
          },
          pincodeIds: whereFilter?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdminV2({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.NEW,
          orderIds: whereFiltersCopy?.orderNumber,
          deliveryType: whereFiltersCopy?.deliveryType,
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : statusFilter,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFiltersCopy?.deliveredOn?.from?.$d,
            to: whereFiltersCopy?.deliveredOn?.to?.$d,
          },
          pincodeIds: whereFiltersCopy?.pincodes,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];
      const orderStatus = map(
        filter(
          ORDER_STATUS,
          (item) =>
            (isAssignDelPartnerTab
              ? item?.value === ORDER_STATUS?.READY_TO_SHIP?.value
              : item?.value === ORDER_STATUS?.PLACED?.value) ||
            item?.value === ORDER_STATUS?.CONFIRMED?.value,
        ),
        (item) => item,
      );
      switch (filterIndex) {
        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.DELIVERY?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'users':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.USER?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'pincodes':
          pincodes({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: {
                field: 'pincode',
                order: 'ASC',
              },
            },
          });
          break;

        case 'deliveryType':
          forEach(DELIVERY_TYPE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'orderNumber':
          orderSelect({
            variables: {
              filter: {
                ...initialTableFilter,
                orderType: ORDER_TYPE?.NEW,
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'status':
          forEach(orderStatus, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isUsersLoading &&
      !isPincodesLoading &&
      !isOrderSelectLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.DELIVERY?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'users':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.USER?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'pincodes':
              pincodes({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'pincode',
                    order: 'ASC',
                  },
                },
              });
              break;

            case 'orderNumber':
              orderSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    orderType: ORDER_TYPE?.NEW,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.DELIVERY?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'users':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.USER?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'pincodes':
        pincodes({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'pincode',
              order: 'ASC',
            },
          },
        });
        break;

      case 'orderNumber':
        orderSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              orderType: ORDER_TYPE?.NEW,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'deliveredOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleExpand = async (expanded, record) => {
    setIsLoading({
      [record?.id]: true,
    });
    setIsDisabled(true);
    if (!nestedData?.[record?.id]) {
      const res = await orderItemsAdmin({
        variables: {
          where: {
            id: record?.id,
          },
        },
      });
      if (res?.data) {
        setNestedData((state) => ({
          ...state,
          [record?.id]: res?.data,
        }));
      }
      setIsDisabled(false);
    }
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record?.id]);
    } else {
      setExpandedRowKeys(expandedRowKeys?.filter((key) => key !== record?.id));
    }
    setIsLoading({
      [record?.id]: false,
    });
    setIsDisabled(false);
  };

  const handleOrderChange = (value, record) => {
    setOrder(record);
    setIsOrderAction(true);
    if (value === FULFILLMENT_STATUS?.FULFILLED?.value) {
      setIsReadyPrompts(true);
    } else {
      setIsAddCommentModalOpen(true);
    }
  };

  const handleOrderItemChange = (value, record, orderDetails) => {
    setOrderItem(record);
    setOrder(orderDetails);
    setIsOrderAction(false);
    if (value === FULFILLMENT_STATUS?.FULFILLED?.value) {
      setIsFulfillPrompts(true);
    } else {
      setIsAddCommentModalOpen(true);
    }
  };

  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      sorter: true,
      width: 150,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (_, record) => record?.orderNumber,
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      align: 'left',
      width: 100,
      className: 'max-width-column',
      render: (_, record) => record?.orderItemCount || '-',
    },
    {
      title: 'DELIVERY TYPE',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) =>
        DELIVERY_TYPE?.[record?.deliveryType]?.label || '-',
      ...filterPopup('deliveryType'),
    },
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      onCell: (record) => ({
        onClick: () =>
          isDeliveryPartnerPermission &&
          record?.deliveryPartner?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/view/${record?.deliveryPartner?.id}`,
          ),
        className: `${
          isUserPermission &&
          record?.deliveryPartner?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          {record?.deliveryPartner?.firstName ||
          record?.deliveryPartner?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.deliveryPartner?.firstName
                ? record?.deliveryPartner?.firstName
                : ''
            } ${
              record?.deliveryPartner?.lastName
                ? record?.deliveryPartner?.lastName
                : ''
            }`}</div>
          ) : (
            '-'
          )}
          {record?.deliveryPartner?.userNumber ? (
            <div className="font-size-12">
              ({record?.deliveryPartner?.userNumber})
            </div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) => record?.pincode?.pincode || '-',
      ...filterPopup('pincodes'),
    },
    {
      title: 'PLACED ON',
      dataIndex: 'placedOn',
      key: 'placedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'ESTIMATED SHIPPING DATE',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.deliveredOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('deliveredOn'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={ORDER_STATUS?.[record?.status]?.color}
          text={ORDER_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 250,
      render: (_, record) => {
        if (isAssignDelPartnerTab) {
          const { firstName, lastName, id, userNumber } =
            record?.deliveryPartner || {};
          const assignedDeliveryPartner = {
            firstName,
            lastName,
            id,
            userNumber,
          };
          return (
            <UserSelect
              size="small"
              className="user-select"
              placeholder="Select Delivery Partner"
              showAction={['click']}
              value={id || null}
              userData={id ? assignedDeliveryPartner : null}
              roles={USER_ROLES?.DELIVERY?.key}
              onChange={(value, userData) => {
                setOrder(record);
                setDeliveryPartnerData(userData);
                setIsDeliveryPartnerPrompts(true);
              }}
              allowClear={false}
            />
          );
        }
        let selectedValue = null;
        if (
          record?.status === ORDER_STATUS?.CANCELLED?.value ||
          record?.status === ORDER_STATUS?.CONFIRMED?.value
        ) {
          if (record?.status === ORDER_STATUS?.CANCELLED?.value) {
            selectedValue = FULFILLMENT_STATUS?.UNFULFILLED?.value;
          } else {
            selectedValue = FULFILLMENT_STATUS?.FULFILLED?.value;
          }
        } else {
          selectedValue = null;
        }
        return (
          <>
            <SelectComponent
              size="small"
              className="full-width"
              placeholder="Select Action"
              showAction={['click']}
              value={selectedValue}
              showSearch={false}
              disabled={!!selectedValue}
              options={NEW_ORDER_TABLE_ACTIONS_OPTIONS}
              onSelect={(value) => handleOrderChange(value, record)}
              allowClear={false}
            />
          </>
        );
      },
    },
  ]?.filter((item) => {
    if (!isAssignDelPartnerTab && item?.dataIndex === 'deliveryPartnerName') {
      return item?.hidden;
    }
    return !item?.hidden;
  });
  const expandedRowRender = (data) => {
    const orderItemList = nestedData?.[data?.id]?.orderItemsAdmin?.data;
    const nestedColumns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        key: 'productName',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
        onCell: (record) => ({
          onClick: () =>
            isToyPermission &&
            record?.toy?.id &&
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.toy?.id}`,
            ),
          className: `${
            isToyPermission && record?.toy?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => (
          <>
            <div className="table-data-with-id">{record?.toy?.name || '-'}</div>
            {record?.toy?.toyNumber && (
              <div className="font-size-12">({record?.toy?.toyNumber})</div>
            )}
          </>
        ),
      },
      {
        title: 'IMAGES',
        dataIndex: 'images',
        key: 'images',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => {
          const images = record?.toy?.images;
          return (
            <span>
              {images?.length > 0 ? (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(images);
                    }}
                    className="pointer"
                  />
                </>
              ) : (
                <span>No Images</span>
              )}
              {images?.length > 1 ? ` + ${images?.length - 1}` : ''}
            </span>
          );
        },
      },
      {
        title: 'BRAND',
        dataIndex: 'brand',
        key: 'brand',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.toy?.brand?.name || '-',
      },
      {
        title: 'AGE GROUPS',
        dataIndex: 'ageGroups',
        key: 'ageGroups',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.toy?.ageGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          ),
      },
      {
        title: 'FULFILLMENT STATUS',
        dataIndex: 'fulfillmentStatus',
        key: 'fulfillmentStatus',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.CANCELLED?.value ? (
            <Badge
              color={FULFILLMENT_STATUS?.[record?.status]?.color}
              text={FULFILLMENT_STATUS?.[record?.status]?.text}
            />
          ) : (
            '-'
          ),
      },
      {
        title: 'EXPECTED RETURN',
        dataIndex: 'returnDate',
        key: 'returnDate',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) =>
          formatDate(record?.returnDate, DATE_FORMATS?.REGULAR) || '-',
      },
      {
        title: 'NOTE',
        dataIndex: 'note',
        key: 'note',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => (
          <div className="action-button">
            {record?.cancellationData?.type === CANCELLATION_TYPE?.LIBRARY ? (
              <Tooltip title="View comment" placement="top" zIndex={4}>
                <CommentIcon
                  className="mr-24 pointer svg-icon"
                  onClick={() => {
                    setIsNotePrompts(true);
                    setOrderItem(record);
                    setIsOrderAction(false);
                  }}
                />
              </Tooltip>
            ) : (
              <span className="mr-24">-</span>
            )}
          </div>
        ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: 250,
        render: (_, record) => (
          <SelectComponent
            size="small"
            className="full-width"
            placeholder="Select Action"
            showSearch={false}
            value={
              record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value
                ? record?.status
                : null
            }
            disabled={
              record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
              record?.status === FULFILLMENT_STATUS?.CANCELLED?.value
            }
            options={NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS}
            onChange={(value) => handleOrderItemChange(value, record, data)}
            allowClear={false}
          />
        ),
      },
    ]?.filter((item) => {
      if (isAssignDelPartnerTab && item?.dataIndex === 'actions') {
        return item?.hidden;
      }
      return !item?.hidden;
    });

    return (
      <CommonTable
        loading={isLoading[data?.id]}
        columns={nestedColumns}
        dataSource={orderItemList || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const expandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded disabled"
        aria-label="Expand row"
        aria-expanded="false"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    ) : (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Collapse row"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    );

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setOrderItem({});
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: isAssignDelPartnerTab
        ? false
        : record?.status === ORDER_STATUS?.CANCELLED?.value ||
          record?.status === ORDER_STATUS?.CONFIRMED?.value,
    }),
  };

  return (
    <>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={orderItem?.contentHistory}
        handleCancel={handleCancel}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isDeliveryPartnerPrompts}
        onOk={() =>
          handleAssignDeliveryPartner([order?.id], deliveryPartnerData?.value)
        }
        onCancel={() => setIsDeliveryPartnerPrompts(false)}
        okButtonProps={{ loading: isDeliveryPartnerLoading }}
      >
        Are you sure you want to assign
        <strong> {deliveryPartnerData?.children} </strong>?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isFulfillPrompts}
        onOk={handleFulfillOrderItem}
        onCancel={() => setIsFulfillPrompts(false)}
        okButtonProps={{ loading: isFulfillLoading }}
      >
        Are you sure you want to
        <strong> Mark as fulfill </strong>
        this order item?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isReadyPrompts}
        onOk={() => handleReadyOrder([order?.id])}
        onCancel={() => setIsReadyPrompts(false)}
        okButtonProps={{ loading: isReadyLoading }}
      >
        Are you sure you want to
        <strong> Mark as ready </strong>
        this order?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isConfirmPrompts}
        onOk={() => handleReadyOrder(selectedRowKeys)}
        onCancel={() => setIsConfirmPrompts(false)}
        okButtonProps={{ loading: isReadyLoading }}
      >
        Are you sure you want to
        <strong> Confirm </strong>
        this orders?
      </Modal>
      <AddCommentModal
        form={form}
        isOrderAction={isOrderAction}
        isModalOpen={isAddCommentModalOpen}
        setIsModalOpen={setIsAddCommentModalOpen}
        isBtnLoading={isAddCommentLoading}
        onFinish={
          isOrderAction ? handleAddCommentInOrder : handleAddCommentInOrderItem
        }
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <NoteModal
        note={
          isOrderAction ? order?.cancellationData : orderItem?.cancellationData
        }
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
        isOrderAction={isOrderAction}
        adminNote={
          isOrderAction ? order?.comment?.comment : orderItem?.comment?.comment
        }
      />
      <AssignDeliveryPartnerModal
        form={assignDelPartnerForm}
        onFinish={(values) =>
          handleAssignDeliveryPartner(selectedRowKeys, values?.name)
        }
        loadings={isDeliveryPartnerLoading}
        isModalOpen={isDeliveryPartnerModalOpen}
        setIsModalOpen={setDeliveryPartnerModalOpen}
        title="Assign Delivery Partner"
      />
      <div className="d-flex justify-end align-center mb-16">
        {isAssignDelPartnerTab ? (
          <Button
            type="primary"
            disabled={!hasSelected}
            className="mr-8"
            onClick={() => setDeliveryPartnerModalOpen(true)}
          >
            Assign Delivery Partner
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={!hasSelected}
            className="mr-8"
            onClick={() => setIsConfirmPrompts(true)}
          >
            Confirm Orders
          </Button>
        )}
        <SearchComponent
          className="search-box"
          query={orderSearchTerm}
          setQuery={setOrderSearchTerm}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        expandedRowKeys={expandedRowKeys}
        onExpand={(_, record) => setOrder(record)}
        expandable={{
          onExpand: (expanded, record) => handleExpand(expanded, record),
          expandedRowRender: (record) => expandedRowRender(record),
          expandIcon,
        }}
      />
    </>
  );
};

export default NewOrderTable;
