import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SUBSCRIPTION_PLANS_ADMIN = gql`
  query SubscriptionPlansAdmin(
    $filter: SubscriptionPlanFilter
    $sort: SubscriptionPlanSort
  ) {
    subscriptionPlansAdmin(filter: $filter, sort: $sort) {
      data {
        id
        name
        planId
        price
        type
        subscriptionFeatures {
          bonusDeliveries
          deliveryCharges
          discount
          memberShipFees
          refundableDeposit
          totalFreeDeliveryPerMonth
          totalKeepCountPerMonth
          toyProgram
          actualChargesForDamageWaiverFees
          actualChargesForSecurityDeposit
          actualChargesForMemberShipFees
          damageWaiverFees
          damageWaiverFeesApplied
          membershipFeesApplied
          securityDepositApplied
          trialAmount
          trialApplied
          trialDays
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const SUBSCRIPTION_PLANS_ADMIN_SELECT = gql`
  query SubscriptionPlansAdmin(
    $filter: SubscriptionPlanFilter
    $sort: SubscriptionPlanSort
  ) {
    subscriptionPlansAdmin(filter: $filter, sort: $sort) {
      count
      data {
        planId
        name
        type
        id
        isPopular
        price
        subscriptionFeatures {
          memberShipFees
          refundableDeposit
          planVersion
        }
      }
    }
  }
`;
