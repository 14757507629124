import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dropdown, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { PLAN_NAMES, PLAN_TYPES } from '../../../common/constants';
import { UPDATE_SUBSCRIPTION_PLAN } from '../graphql/Mutations';
import { SUBSCRIPTION_PLANS_ADMIN_SELECT } from '../graphql/Queries';

const PopularPlanSelect = () => {
  const [planList, setPlanList] = useState();
  const [selectedKey, setSelectedKey] = useState({ key: '', label: '' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChangePlanLoading, setIsChangePlanLoading] = useState(false);
  const [isChangePlanPrompts, setIsChangePlanPrompts] = useState(false);
  const [planData, setPlanData] = useState({});

  const [subscriptionPlansAdmin, { loading: isPlansLoading }] = useLazyQuery(
    SUBSCRIPTION_PLANS_ADMIN_SELECT,
    {
      onCompleted: (response) => {
        const filteredData = response?.subscriptionPlansAdmin?.data?.filter(
          (item) => item?.name !== PLAN_NAMES?.TRIAL?.value,
        );
        setPlanList(filteredData);
        const popularPlan = response?.subscriptionPlansAdmin?.data?.filter(
          (plan) => plan?.isPopular === true,
        );
        if (popularPlan?.length > 0) {
          setSelectedKey({
            key: popularPlan?.[0]?.id,
            label: `${PLAN_NAMES?.[popularPlan?.[0]?.name]?.key}-${
              PLAN_TYPES?.[popularPlan?.[0]?.type]?.key
            }`,
          });
        } else {
          setSelectedKey({
            label: 'No Plan Selected',
          });
        }
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN, {
    onError: () => {},
  });

  useEffect(() => {
    subscriptionPlansAdmin({
      variables: {
        filter: {
          planVersion: `v${process.env.REACT_APP_PLAN_VERSION}`,
        },
      },
    });
  }, []);

  const planListArray = planList?.map((plan) => ({
    key: plan?.id,
    label: `${PLAN_NAMES?.[plan?.name]?.key}-${
      PLAN_TYPES?.[plan?.type]?.key
    } (${plan?.subscriptionFeatures?.planVersion})`,
  }));

  const handleMenuChange = (e) => {
    setIsChangePlanPrompts(true);
    setPlanData(e);
    setIsMenuOpen(false);
  };

  const handleChangePlan = async (e) => {
    setIsChangePlanLoading(true);
    const selectedPlans = planList?.filter((plan) => plan?.id === e?.key);
    const res = await updateSubscriptionPlan({
      variables: {
        where: {
          id: e?.key,
        },
        data: {
          isPopular: true,
        },
      },
    });
    if (res?.data) {
      setIsChangePlanPrompts(false);
      if (selectedPlans?.length > 0) {
        setSelectedKey({
          key: selectedPlans?.[0]?.id,
          label: `${PLAN_NAMES?.[selectedPlans?.[0]?.name]?.key}-${
            PLAN_TYPES?.[selectedPlans?.[0]?.type]?.key
          }`,
        });
      } else {
        setSelectedKey({
          label: 'No Plan Selected',
        });
      }
    }
    setIsChangePlanLoading(false);
  };

  const handleDropdownVisibleChange = (visible) => {
    setIsMenuOpen(visible);
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isChangePlanPrompts}
        onOk={() => handleChangePlan(planData)}
        onCancel={() => setIsChangePlanPrompts(false)}
        okButtonProps={{ loading: isChangePlanLoading }}
      >
        Are you sure you want to assign
        <strong> change </strong>the current most popular plan?
      </Modal>
      {!isPlansLoading && (
        <>
          <strong>Current Most Popular - </strong>
          <Dropdown
            menu={{
              items: planListArray,
              selectable: true,
              onSelect: handleMenuChange,
              selectedKeys: [selectedKey?.key],
            }}
            trigger={['click']}
            onOpenChange={handleDropdownVisibleChange}
          >
            <Typography.Link>
              <Space className="plan-dropdown">
                <strong>{selectedKey?.label}</strong>
                {isMenuOpen ? <UpOutlined /> : <DownOutlined />}
              </Space>
            </Typography.Link>
          </Dropdown>
        </>
      )}
    </>
  );
};

export default PopularPlanSelect;
