import { DeleteFilled, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Button, Divider, Form, Modal, Radio, Tooltip } from 'antd';
import { forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/svg/play-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
  VIDEO_STATUS,
  VIDEO_TYPE,
} from '../../../../common/constants';
import {
  fileUpload,
  formatDate,
  getVideoDetails,
  hasPermission,
  openNotification,
} from '../../../../common/utils';
import CommonPreviewTable from '../../../../components/CommonPreviewTable';
import CommonTable from '../../../../components/CommonTable';
import SearchComponent from '../../../../components/SearchComponent';
import ToyVideoModal from '../../../../libraryModules/raiseRequest/components/ToyVideoModal';
import { CREATE_VIDEO_STORY, REMOVE_VIDEO_STORY, UPDATE_VIDEO_STORY } from '../graphql/Mutations';
import {
  GET_VIDEO_SIGNED_PUT_URL,
  VIDEOS_STORYS_ADMIN,
} from '../graphql/Queries';
import StoryAddTable from './StoryAddTable';

const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const ViewStoryTable = ({ isStoryVideoModalOpen, setIsStoryVideoModalOpen, storyId }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [videoList, setVideoList] = useState([]);
  const [videoSearchTerm, setVideoSearchTerm] = useState('');
  const [isEmptyVideoList, setIsEmptyVideoList] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [visible, setVisible] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isThumbVideoModalOpen, setIsThumbVideoModalOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [thumbVideoError, setThumbVideoError] = useState('');
  const [videoError, setVideoError] = useState('');
  const [isHomeVideoLoading, setIsHomeVideoLoading] = useState(false);
  const [toyVideo, setToyVideo] = useState([]);
  const [toyPreviewVideo, setToyPreviewVideo] = useState(null);
  const [thumbVideo, setthumbVideo] = useState([]);
  const [thumbPreviewVideo, setthumbPreviewVideo] = useState(null);
  const [toySelectValue, setToySelectValue] = useState(null);
  const [isToyDelete, setIsToyDelete] = useState(false);
  const [videoId, setVideoId] = useState();
  const [videoType, setVideoType] = useState(VIDEO_TYPE?.INTERNAL?.value);

  const [whereFilter, setWhereFilter] = useState();
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [videosStoryAdmin] = useLazyQuery(VIDEOS_STORYS_ADMIN, {
    onCompleted: (response) => {
      setVideoList([...response?.videosStoryAdmin?.data]);
      if (
        response?.videosStoryAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyVideoList(true);
      } else {
        setIsEmptyVideoList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.videosStoryAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsVideoLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const [createStoryVideo] = useMutation(CREATE_VIDEO_STORY, {
    onError: () => { },
  });
  const [removeVideo] = useMutation(REMOVE_VIDEO_STORY, {
    onError: () => { },
  });
  const [updateStoryVideo] = useMutation(UPDATE_VIDEO_STORY, {
    onError: () => { },
  });

  const [getVideoSignedPutUrl] = useLazyQuery(GET_VIDEO_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsHomeVideoLoading(false);
    },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.APP_CONTENT_STORY,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsVideoLoading(true);
    videosStoryAdmin({
      variables: {
        where: {
          id: storyId,
        },
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: videoSearchTerm,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsVideoLoading(true);
    setSortedInfo(sorter);
    videosStoryAdmin({
      variables: {
        where: {
          id: storyId,
        },
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: videoSearchTerm,
          status: finalWhereFilters?.status,
          type: finalWhereFilters?.type,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };
  const handleVideoRemove = async () => {
    setIsVideoLoading(true);
    try {
      const response = await removeVideo({
        variables: {
          where: {
            id: videoId,
          },
        },
      });
      if (response?.data) {
        setIsToyDelete(false);
        videosStoryAdmin({
          variables: {
            where: {
              id: storyId,
            },
            filter: {
              ...initialFilter,
              limit: paginationProp?.pageSize || LIMIT,
              search: videoSearchTerm,
              status: finalWhereFilters?.status,
              type: finalWhereFilters?.type,
            },
            sort: sortedInfo?.column
              ? {
                field: sortedInfo?.field,
                order:
                  sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
              : initialSort,
          },
        });
      }
    } catch (error) {
      setIsHomeVideoLoading(false);
      return error;
    }
  };
  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setVideoSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsVideoLoading(true);
    videosStoryAdmin({
      variables: {
        where: {
          id: storyId,
        },
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          status: finalWhereFilters?.status,
          type: finalWhereFilters?.type,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };
  const handleHomeVideo = async (values) => {

    setIsHomeVideoLoading(true);
    const uuid = uuidv4();
    let video = '';
    let thumbvideos = '';

    let thumbvideoDetails;
    if (!values?.thumbvideo?.[0]?.url) {
      thumbvideoDetails = await getVideoDetails(values?.thumbvideo?.[0]?.originFileObj);
    }

    if (thumbvideoDetails?.duration > 6) {
      setThumbVideoError("Thumbnail Video can be Added Maximum of 5 seconds")
      setIsHomeVideoLoading(false);
      return false
    }


    const thumbvideoDetailsMetaData = {
      durationInSeconds: thumbvideoDetails?.duration,
      extension: thumbvideoDetails?.type,
      height: thumbvideoDetails?.height,
      sizeInMb: thumbvideoDetails?.sizeInMB,
      width: thumbvideoDetails?.width,
    };

    let videoDetails;
    if (!values?.video?.[0]?.url) {
      videoDetails = await getVideoDetails(values?.video?.[0]?.originFileObj);
    }
    if (videoDetails?.duration < 11) {
      setVideoError("Video can be Added Minimum of 10 second")
      setIsHomeVideoLoading(false);
      return false
    }
    if (videoDetails?.duration > 31) {
      setVideoError("Video can be Added Maximum of 30 seconds")
      setIsHomeVideoLoading(false);
      return false
    }
    const videoDetailsMetaData = {
      durationInSeconds: videoDetails?.duration,
      extension: videoDetails?.type,
      height: videoDetails?.height,
      sizeInMb: videoDetails?.sizeInMB,
      width: videoDetails?.width,
    };

    if (videoType === VIDEO_TYPE?.INTERNAL?.value) {
      if (values?.thumbvideo?.length > 0 && !values?.thumbvideo?.[0]?.url) {
        const { name } = values?.thumbvideo?.[0];
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        const res = await getVideoSignedPutUrl({
          variables: {
            data: {
              fileName: fileKey,
              videoUuid: uuid,
            },
          },
        });
        if (res?.data) {
          try {
            try {
              await fileUpload(
                res?.data?.getVideoSignedPutUrl?.signedUrl,
                thumbVideo?.[0]?.originFileObj,
              );
              thumbvideos = res?.data?.getVideoSignedPutUrl?.fileName;
            } catch (error) {
              throw new Error(
                `${thumbVideo?.name} upload failed. Please try again.`,
              );
            }
          } catch (error) {
            setIsHomeVideoLoading(false);
            openNotification('error', error.message);
          }
        }
      }
    }

    if (videoType === VIDEO_TYPE?.INTERNAL?.value) {
      if (values?.video?.length > 0 && !values?.video?.[0]?.url) {
        const { name } = values?.video?.[0];
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        const res = await getVideoSignedPutUrl({
          variables: {
            data: {
              fileName: fileKey,
              videoUuid: uuid,
            },
          },
        });
        if (res?.data) {
          try {
            try {
              await fileUpload(
                res?.data?.getVideoSignedPutUrl?.signedUrl,
                toyVideo?.[0]?.originFileObj,
              );
              video = res?.data?.getVideoSignedPutUrl?.fileName;
            } catch (error) {
              throw new Error(
                `${toyVideo?.name} upload failed. Please try again.`,
              );
            }
          } catch (error) {
            setIsHomeVideoLoading(false);
            openNotification('error', error.message);
          }
        }
      }
    }

    try {
      let response;
      if (videoData) {
        const videoMetaData = videoData?.metaData;
        const thumbnailmetaData = videoData?.thumbnailmetaData;
        response = await updateStoryVideo({
          variables: {
            where: {
              id: videoData?.id,
            },
            data: {
              thumbnailKey: videoType === VIDEO_TYPE?.INTERNAL?.value
                ? values?.thumbvideo?.[0]?.key || thumbvideos
                : values?.videoUrl,
              key:
                videoType === VIDEO_TYPE?.INTERNAL?.value
                  ? values?.video?.[0]?.key || video
                  : values?.videoUrl,
              type: videoType,
              description: values?.description,
              toyId: values?.toyId,
              status: values?.status,
              metaData: !values?.video?.[0]?.url
                ? videoDetailsMetaData
                : {
                  durationInSeconds: videoMetaData?.durationInSeconds,
                  extension: videoMetaData?.extension,
                  height: videoMetaData?.height,
                  sizeInMb: videoMetaData?.sizeInMb,
                  width: videoMetaData?.width,
                },
              thumbnailmetaData: !values?.thumbvideo?.[0]?.url
                ? thumbvideoDetailsMetaData
                : {
                  durationInSeconds: thumbnailmetaData?.durationInSeconds,
                  extension: thumbnailmetaData?.extension,
                  height: thumbnailmetaData?.height,
                  sizeInMb: thumbnailmetaData?.sizeInMb,
                  width: thumbnailmetaData?.width,
                },
            },
          },
        });
      } else {
        response = await createStoryVideo({
          variables: {
            where: {
              id: storyId,
            },
            data: {
              key:
                videoType === VIDEO_TYPE?.INTERNAL?.value
                  ? video
                  : values?.videoUrl,
              thumbnailKey: videoType === VIDEO_TYPE?.INTERNAL?.value
                ? thumbvideos
                : values?.videoUrl,
              type: videoType,
              description: values?.description,
              toyId: values?.toyId,
              status: values?.status,
              metaData: videoDetailsMetaData,
              thumbnailmetaData: thumbvideoDetailsMetaData,
            },
          },
        });
      }
      if (response?.data) {
        setIsVideoLoading(true);
        setIsHomeVideoLoading(false);
        setIsStoryVideoModalOpen(false);
        form?.resetFields();
        setVideoList([]);
        setVideoType(VIDEO_TYPE?.INTERNAL?.value);
        setToyVideo([]);
        setthumbVideo([]);
        setToyPreviewVideo(null);
        setthumbPreviewVideo(null);
        setVideoData(null);
        videosStoryAdmin({
          variables: {
            where: {
              id: storyId,
            },
            filter: {
              ...initialFilter,
              limit: paginationProp?.pageSize || LIMIT,
              search: videoSearchTerm,
              status: finalWhereFilters?.status,
              type: finalWhereFilters?.type,
            },
            sort: sortedInfo?.column
              ? {
                field: sortedInfo?.field,
                order:
                  sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
              : initialSort,
          },
        });
      }
    } catch (error) {
      return error;
    }
    setIsHomeVideoLoading(false);

  };

  const getFilterData = (confirm) => {
    setIsHomeVideoLoading(true);
    videosStoryAdmin({
      variables: {
        where: {
          id: storyId,
        },
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: videoSearchTerm,
          status: whereFilter?.status,
          type: whereFilter?.type,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: null,
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsHomeVideoLoading(true);
    videosStoryAdmin({
      variables: {
        where: {
          id: storyId,
        },
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: videoSearchTerm,
          status: whereFiltersCopy?.status,
          type: whereFiltersCopy?.type,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(VIDEO_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          break;

        case 'type':
          forEach(VIDEO_TYPE, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={map(VIDEO_STATUS, (option) => option)}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <Group
            options={map(VIDEO_TYPE, (option) => option)}
            value={whereFilter?.[dataIndex]}
            onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
          />
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
        !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'Toy',
      dataIndex: 'toy',
      key: 'toy',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.toy ? record?.toy.name : '--'}</div>
          <div className="font-size-12">({record?.toy ? record?.toy.toyNumber : '--'})</div>
        </>
      ),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      render: (_, record) => (
        <Badge
          color={VIDEO_STATUS?.[record?.status]?.color}
          text={VIDEO_STATUS?.[record?.status]?.label}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'THUMB VIDEO',
      dataIndex: 'thumbvideo',
      key: 'thumbvideo',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.thumbnailUrl ? (
            <Tooltip title="Play" placement="top" zIndex={4}>
              <PlayIcon
                onClick={() => {
                  setVideoData(record);
                  setIsThumbVideoModalOpen(true);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      title: 'VIDEO',
      dataIndex: 'video',
      key: 'video',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.url ? (
            <Tooltip title="Play" placement="top" zIndex={4}>
              <PlayIcon
                onClick={() => {
                  setVideoData(record);
                  setIsVideoModalOpen(true);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <>
              <Tooltip title="Edit" placement="top" zIndex={4}>
                <EditIcon
                  onClick={() => {
                    setVideoData(record);
                    setIsStoryVideoModalOpen(true);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
              <Tooltip title="Delete" placement="top" zIndex={4}>
                <DeleteFilled
                  style={{ fontSize: '16px', color: '#9264a8' }}
                  onClick={() => {
                    setIsToyDelete(true);
                    setVideoId(record?.id);
                  }}
                />
              </Tooltip>
            </>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyVideoList ? '' : <span />,
  };

  return (
    <>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isToyDelete}
        onOk={() => handleVideoRemove(videoId)}
        onCancel={() => {
          setIsToyDelete(false);
        }}
      >
        Are you sure you want to
        delete
        this toy?
      </Modal>
      <ToyVideoModal
        title="Video"
        isModalOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        videoUrl={videoData?.url}
        onCancel={() => {
          setToyVideo([]);
          setToyPreviewVideo(null);
          setVideoData(null);
          setVideoType(VIDEO_TYPE?.INTERNAL?.value);
          form?.resetFields();
        }}
        isIframe={videoData?.type === VIDEO_TYPE?.EXTERNAL?.value}
      />
      <ToyVideoModal
        title="Thumb Video"
        isModalOpen={isThumbVideoModalOpen}
        setIsModalOpen={setIsThumbVideoModalOpen}
        videoUrl={videoData?.thumbnailUrl}
        onCancel={() => {
          setthumbVideo([]);
          setthumbPreviewVideo(null);
          setVideoData(null);
          setVideoType(VIDEO_TYPE?.INTERNAL?.value);
          form?.resetFields();
        }}
        isIframe={videoData?.type === VIDEO_TYPE?.EXTERNAL?.value}
      />
      <StoryAddTable
        title={videoData ? 'Edit Video' : 'Add New Video'}
        videoData={videoData}
        setVideoData={setVideoData}
        videoType={videoType}
        setVideoType={setVideoType}
        isModalOpen={isStoryVideoModalOpen}
        setIsModalOpen={setIsStoryVideoModalOpen}
        isBtnLoading={isHomeVideoLoading}
        onFinish={handleHomeVideo}
        thumbVideoError={thumbVideoError}
        setThumbVideoError={setThumbVideoError}
        videoError={videoError}
        setVideoError={setVideoError}
        form={form}
        toyVideo={toyVideo}
        setToyVideo={setToyVideo}
        toyPreviewVideo={toyPreviewVideo}
        setToyPreviewVideo={setToyPreviewVideo}
        thumbVideo={thumbVideo}
        setthumbVideo={setthumbVideo}
        thumbPreviewVideo={thumbPreviewVideo}
        setthumbPreviewVideo={setthumbPreviewVideo}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={videoList || []}
        loading={isVideoLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ViewStoryTable;
