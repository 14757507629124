import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Row, Upload } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ALLOW_CSV_FILE, ROUTES } from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';

const BulkUpdateModal = ({
  form,
  isModalOpen,
  setIsModalOpen,
  handleExport,
  isExportLoading,
  loadings,
  onFinish,
  bulkUpdateFile,
  setBulkUpdateFile,
  isGoExportVisible,
  setIsGoExportVisible,
  isDesktop,
}) => {
  const history = useHistory();
  const handleChange = (info) => {
    const {
      file: { name: fileName = '', key = '' },
    } = info;
    const ext = fileName?.substring(fileName?.lastIndexOf('.') + 1);
    const extKey = key?.substring(key?.lastIndexOf('.') + 1);
    if (ALLOW_CSV_FILE?.includes(ext || extKey)) {
      form?.setFieldsValue({
        bulkUpdateFile: info?.fileList,
      });
      setBulkUpdateFile(info?.fileList);
    } else {
      setBulkUpdateFile(info?.fileList?.slice(0, -1));
      form?.setFieldsValue({
        bulkUpdateFile: info?.fileList?.slice(0, -1),
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not csv file.`);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setBulkUpdateFile([]);
    setIsGoExportVisible(false);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Toy Bulk Update"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        okButtonProps={{ disabled: bulkUpdateFile?.length === 0 }}
      >
        <Divider />
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <Row
            align="middle"
            gutter={[8, isDesktop ? 24 : 12]}
            className="mb-48"
          >
            <Col xs={24} sm={19} md={19} lg={19} xl={19} xxl={19}>
              <div className="font-size-16">
                <strong>Step 1</strong>
              </div>
              <div>Download file with toy data</div>
            </Col>
            <Col
              xs={24}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className={`d-flex ${isDesktop ? 'justify-end' : ''}`}
            >
              <div className="d-flex flex-vertical">
                <Button
                  className="add-toy-btn"
                  type="primary"
                  onClick={handleExport}
                  loading={isExportLoading}
                >
                  Download
                </Button>
                {isGoExportVisible && (
                  <Button
                    type="link"
                    className="p-0 go-to-exports"
                    onClick={() => history?.push(ROUTES?.EXPORTS)}
                  >
                    Go to Exports
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={24} sm={19} md={19} lg={19} xl={19} xxl={19}>
              <div className="font-size-16">
                <strong>Step 2</strong>
              </div>
              <div>Upload file with toy data</div>
            </Col>
            <Col
              xs={24}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className={`d-flex ${isDesktop ? 'justify-end' : ''
                } bulk-update-import`}
            >
              <Form.Item name="bulkUpdateFile">
                <Upload
                  maxCount={1}
                  fileList={bulkUpdateFile}
                  beforeUpload={() => false}
                  onChange={handleChange}
                >
                  {bulkUpdateFile?.length === 0 && (
                    <Button
                      type="primary"
                      className="add-toy-btn"
                      icon={<UploadOutlined />}
                    >
                      Click to Upload
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default BulkUpdateModal;
