import { gql } from '@apollo/client';

export const RETURN_ORDERS = gql`
  query ReturnOrders($filter: ReturnOrderItemFilter, $sort: ReturnOrderSort) {
    returnOrders(filter: $filter, sort: $sort) {
      count
      data {
        id
        status
        toyCondition
        isLibraryDamageRequestGenerated
        includedContents {
          id
          quantity
          value
        }
        contentHistory {
          id
          newContent {
            id
            quantity
            value
          }
          oldContent {
            id
            quantity
            value
          }
          status
          createdAt
        }
        includedContents {
          id
          quantity
          value
        }
        deliveryAttachments {
          key
          url
        }
        returnedOn
        productRate
        videoUrl
        images {
          key
          url
        }
        orderItem {
          toy {
            ageGroups {
              id
              minAge
              maxAge
            }
            brand {
              id
              name
            }
            images {
              contentType
              key
              order
              size
              url
            }
            name
            toyNumber
          }
        }
        order {
          orderNumber
          deliveryType
        }
        returnOrder {
          id
          returnOrderNumber
          pincode {
            pincode
          }
          user {
            userNumber
          }
          deliveryPartner {
            firstName
            lastName
          }
        }
        createdAt
      }
    }
  }
`;

export const GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL = gql`
  query GetLibraryDamageRequestImageSignedPutUrl(
    $data: GetLibraryDamageRequestSignedPutUrlV2Input
  ) {
    getLibraryDamageRequestImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL = gql`
  query GetLibraryRequestToyVideoSignedPutUrl(
    $data: GetLibraryDamageRequestSignedPutUrlInput!
  ) {
    getLibraryRequestToyVideoSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const ORDERS = gql`
  query Orders($filter: OrderFilter, $sort: OrderSort) {
    orders(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
        id
        deliveryType
        orderItemCount
        deliveryAttachments {
          key
          url
        }
        cancelledBy
        deliveredOn
        deliveryPartner {
          id
          userNumber
          firstName
          lastName
        }
        pincode {
          pincode
        }
        user {
          userNumber
        }
        createdAt
        status
        cancellationData {
          date
          additionalComment
          reason
          type
        }
        comment {
          isResolved
          comment
        }
      }
    }
  }
`;

export const ORDER_ITEMS = gql`
  query OrderItems($where: OrderUniqueInput) {
    orderItems(where: $where) {
      count
      data {
        id
        returnDate
        adminNote
        contentHistory {
          id
          newContent {
            id
            quantity
            value
          }
          oldContent {
            id
            quantity
            value
          }
          status
          createdAt
        }
        comment {
          isResolved
          comment
        }
        cancellationData {
          additionalComment
          date
          reason
          type
        }
        toy {
          id
          toyNumber
          name
          ageGroups {
            id
            minAge
            maxAge
          }
          brand {
            id
            name
          }
          images {
            contentType
            key
            order
            size
            url
          }
        }
        status
      }
    }
  }
`;

export const ORDERS_SELECT = gql`
  query Orders($filter: OrderFilter, $sort: OrderSort) {
    orders(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
        id
      }
    }
  }
`;

export const RETURN_ORDERS_SELECT = gql`
  query returnOrders($filter: ReturnOrderItemFilter, $sort: ReturnOrderSort) {
    returnOrders(filter: $filter, sort: $sort) {
      count
      data {
        returnOrder {
          id
          returnOrderNumber
        }
      }
    }
  }
`;

export const GET_ORDER_CHALLAN_DOWNLOAD_URL = gql`
  query GetOrderChallanDownloadUrl($where: OrderUniqueInput!) {
    getOrderChallanDownloadUrl(where: $where) {
      challanUrl
    }
  }
`;

export const GET_ORDER_STICKER_DOWNLOAD_URL = gql`
  query GetOrderStickerDownloadUrl($where: OrderItemUniqueInput!) {
    getOrderStickerDownloadUrl(where: $where) {
      stickerUrl
    }
  }
`;

export const CONTENT_MASTERS = gql`
  query ContentMasters($filter: ContentMasterFilter, $sort: ContentMasterSort) {
    contentMasters(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        value
      }
    }
  }
`;
